import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";
import { TEXT_GRAY } from "../../utils/theme";

export const Text = ({
  children,
  variant = "body", // body, title, subtitle, small
  color = "default",
  style = {},
  onClick = () => {},
}) => {
  const settings = useCustomerPanelSettings();
  const getStyles = () => {
    const baseStyle = {
      title: {
        fontSize: "large",
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: "medium",
        color: TEXT_GRAY,
      },
      body: {
        fontSize: "medium",
      },
      small: {
        fontSize: "9pt",
      },
    };

    const colorStyles = {
      default: {},
      gray: { color: TEXT_GRAY },
      accent: { color: settings.accent_color, cursor: "pointer" },
    };

    return {
      ...baseStyle[variant],
      ...colorStyles[color],
      ...style,
    };
  };

  return (
    <span onClick={onClick} style={getStyles()}>
      {children}
    </span>
  );
};
