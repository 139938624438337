import React from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerTheme } from "../../contexts/CustomerThemeContext";
import { BOX_GRAY, PRIMARY_COLOR, SELECTED_TEXT } from "../../utils/theme";

const ResultItem = ({
  item,
  filterOnAnswerOptionId,
  answerOptionId,
  onAnswerOptionClicked,
}) => {
  const theme = useCustomerTheme();

  const rowStyle = {
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: onAnswerOptionClicked ? "pointer" : "auto",
    transition: "background-color 0.3s ease",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "small",
    flex: 1,
  };

  const valueStyle = {
    flex: 1,
    color: theme.accent_color || PRIMARY_COLOR,
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  return (
    <div
      key={item?.id}
      style={{
        ...rowStyle,
        color: filterOnAnswerOptionId === item.id ? SELECTED_TEXT : "black",
        backgroundColor:
          filterOnAnswerOptionId === item.id ? BOX_GRAY : "transparent",
        borderRadius: `${theme.border_radius}px` || "8px",
        padding: "8px",
      }}
      onClick={() =>
        onAnswerOptionClicked ? onAnswerOptionClicked(item.id) : null
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ maxWidth: "80%" }}>
          <span
            style={{
              ...labelStyle,
              color:
                filterOnAnswerOptionId === item.id
                  ? theme.accent_color || PRIMARY_COLOR
                  : "black",
            }}
          >
            {item.text}{" "}
          </span>
        </div>
        <FadeIn transitionDuration={80} visible={answerOptionId === item.id}>
          <span
            style={{
              marginLeft: "4px",
              color: SELECTED_TEXT,
              fontSize: "small",
            }}
          >
            (ditt svar)
          </span>
        </FadeIn>
      </div>
      <div
        style={{
          ...valueStyle,
          color:
            filterOnAnswerOptionId === item.id
              ? theme.accent_color || PRIMARY_COLOR
              : "black",
        }}
      >
        <span style={{ fontWeight: "bold" }}>{item.percentage}%</span>
      </div>
    </div>
  );
};

export default ResultItem;
