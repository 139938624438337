import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchPanelPluginSettings } from "../helpers/primaryApiFunctions";
import { MENTO_BG_COLOR, PRIMARY_COLOR } from "../utils/theme";

const CustomerPanelSettingsContext = createContext();

const DEFAULT_SETTINGS = {
  button_background_color: MENTO_BG_COLOR,
  accent_color: PRIMARY_COLOR,
  button_image_url: null,
  position: "RIGHT",
  position_padding_bottom: 20,
  position_padding_horizontal: 20,
  border_radius: 8,
  display_mode: "TIME",
  display_after_seconds: 5,
  display_after_scroll_percentage: 50,
  display_total_answers_after: 150,
};

export const CustomerPanelSettingsProvider = ({ panelId, children }) => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await fetchPanelPluginSettings(panelId);
      if (data) {
        // Merge fetched settings with defaults, using defaults for any null values
        const mergedSettings = Object.keys(DEFAULT_SETTINGS).reduce(
          (acc, key) => {
            acc[key] =
              data[key] === null
                ? DEFAULT_SETTINGS[key]
                : data[key] ?? DEFAULT_SETTINGS[key];
            return acc;
          },
          {}
        );
        setSettings(mergedSettings);
      } else {
        setSettings(DEFAULT_SETTINGS);
      }
    };

    if (panelId) {
      fetchSettings();
    }
  }, [panelId]);

  return (
    <CustomerPanelSettingsContext.Provider value={settings}>
      {children}
    </CustomerPanelSettingsContext.Provider>
  );
};

export const useCustomerPanelSettings = () =>
  useContext(CustomerPanelSettingsContext);
