import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchPanelPluginSettings } from "../helpers/primaryApiFunctions";
import { MENTO_BG_COLOR, PRIMARY_COLOR } from "../utils/theme";

const CustomerThemeContext = createContext();

const DEFAULT_THEME = {
  button_background_color: MENTO_BG_COLOR,
  accent_color: PRIMARY_COLOR,
  button_image_url: null,
  position: "RIGHT",
  position_padding_bottom: 20,
  position_padding_horizontal: 20,
  border_radius: 8,
  display_mode: "TIME",
  display_after_seconds: 5,
  display_after_scroll_percentage: 50,
};

export const CustomerThemeProvider = ({ panelId, children }) => {
  const [theme, setTheme] = useState({});

  useEffect(() => {
    const fetchTheme = async () => {
      const data = await fetchPanelPluginSettings(panelId);
      if (data) {
        setTheme(data);
      } else {
        setTheme(DEFAULT_THEME);
      }
    };

    if (panelId) {
      fetchTheme();
    }
  }, [panelId]);

  return (
    <CustomerThemeContext.Provider value={theme}>
      {children}
    </CustomerThemeContext.Provider>
  );
};

export const useCustomerTheme = () => useContext(CustomerThemeContext);
