import React, { useEffect, useRef, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import "../App.css";
import { useCustomerPanelSettings } from "../contexts/CustomerPanelSettingsContext";
import {
  fetchPanel,
  fetchQuestion,
  fetchUser,
  fetchUserAnswer,
  handlePageVisitFromUser,
} from "../helpers/primaryApiFunctions";
import { generateRandomUserId } from "../utils/generateUserId";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/localstorage";
import EmbeddedStart from "./EmbeddedStart";
import { Footer } from "./Footer";
import { MainContentBoxExpanded } from "./MainContentBoxExpanded";
import PluginStart from "./PluginStart";

export const iconSize = 75;
const originalWindowWidth = 250;

function Start({
  userId,
  panelId,
  customerName,
  secretHash,
  displayMode = "plugin",
}) {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [userAnswerOptionId, setUserAnswerOptionId] = useState(null);
  const [activeWebpageId, setActiveWebpageId] = useState(null);
  const [displayBox, setDisplayBox] = useState(false);
  const [user, setUser] = useState(null);
  const [anonymousUserId, setAnonymousUserId] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [registeringVisitLoading, setRegisteringVisitLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(originalWindowWidth);
  const settings = useCustomerPanelSettings();
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [isManuallyHidden, setIsManuallyHidden] = useState(false);

  let position = "RIGHT";
  if (settings && settings.position) {
    position = settings.position;
  }

  const contentRef = useRef(null);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    console.log("[MENTO PLUGIN] Start component mounted.");
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = `
      #mento-plugin-content::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      #mento-plugin-content::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
      #mento-plugin-content::-webkit-scrollbar-track {
        background: transparent;
      }
      #mento-plugin-content {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
      }
      .mento-scroll-container::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      .mento-scroll-container::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
      .mento-scroll-container::-webkit-scrollbar-track {
        background: transparent;
      }
      .mento-scroll-container {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
      }
    `;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  useEffect(() => {
    if (userId && secretHash && customerName && panelId) {
      handleFetchUser(userId);
    } else if (!userId && panelId) {
      // Todo: Check if the panel is allowing anonymous answers, then fetch user.
      handlePotentialAnonymousUser();
    }
  }, [userId, secretHash, customerName, panelId]);

  useEffect(() => {
    // Todo: This is executed twice, when user AND activeQuestion is set. This should only trigger once.
    // Todo: Should this even trigger once if allow_anonymous_answers is true? I
    if (activeQuestion) {
      handleFetchUserAnswerOptionId(
        activeQuestion.id,
        user ? user?.id : undefined
      );
    }
  }, [activeQuestion, user]);

  const handlePotentialAnonymousUser = async () => {
    const panel = await fetchPanel(panelId);
    if (panel?.allow_anonymous_answers) {
      // Todo: Fetch user from local storage. If it doesn't exist, generate a random one and use it
      let newUserId = getLocalStorageItem(panelId + "-anonymous-user");
      if (!newUserId) {
        newUserId = generateRandomUserId();
        setLocalStorageItem(panelId + "-anonymous-user", newUserId);
      }
      setAnonymousUserId(newUserId);
      handleFetchUser(newUserId);
    }
  };

  const handleFetchUserAnswerOptionId = async (questionId, externalUserId) => {
    let aoId = null;
    if (externalUserId) {
      aoId = await fetchUserAnswer(questionId, externalUserId);
    } else {
      aoId = getLocalStorageItem(questionId);
    }
    setUserAnswerOptionId(aoId ?? null);
    if (!aoId) {
      setDisplayBox(true);
    }
    setQuestionLoading(false);
  };

  const handleFetchUser = async (localUserId) => {
    setUserLoading(true);
    const response = await fetchUser(
      localUserId,
      secretHash,
      customerName,
      panelId
    );
    setUser(response);
    setUserLoading(false);
  };

  const handleFetchQuestion = async (url, id) => {
    setQuestionLoading(true);
    let { question, webpageId } = await fetchQuestion(url, id, panelId);

    // Comments out default question functionality for performance reasons. Upon request we can optimise this.
    // if (url && !id && !question) {
    //   // Todo: If there's no question. Trigger edge function.
    //   const defaultQuestionQuery = await supabase.functions.invoke(
    //     "handle-default-question",
    //     {
    //       method: "POST",
    //       body: {
    //         url,
    //         panelId,
    //         webpageId,
    //         customerName,
    //       },
    //     }
    //   );

    //   if (defaultQuestionQuery.data.question) {
    //     question = defaultQuestionQuery.data.question;
    //   }
    //   if (defaultQuestionQuery.data.webpageId) {
    //     webpageId = defaultQuestionQuery.data.webpageId;
    //   }
    // }

    if (question && question.id) {
      setActiveQuestion(question);
      if (webpageId) {
        setActiveWebpageId(webpageId);
      }
    } else {
      setQuestionLoading(false);
      setActiveQuestion(null);
    }
  };

  const fetchQuestionFromCurrentActiveQuestion = () => {
    if (activeQuestion && activeQuestion.id) {
      handleFetchQuestion(null, activeQuestion.id);
    }
  };

  const fetchQuestionFromCurrentURL = () => {
    handleFetchQuestion(window.location.href);
  };

  useEffect(() => {
    let lastPathname = window.location.pathname;
    fetchQuestionFromCurrentURL();

    const observer = new MutationObserver(() => {
      const currentPathname = window.location.pathname;
      if (currentPathname !== lastPathname) {
        lastPathname = currentPathname;
        console.log("Pathname changed to:", currentPathname);
        fetchQuestionFromCurrentURL();
      }
    });

    observer.observe(document, { subtree: true, childList: true });

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (userId && activeQuestion && !!activeQuestion?.id) {
      logUserEvent(userId, activeQuestion?.id);
    } else if (anonymousUserId && activeQuestion && !!activeQuestion?.id) {
      logUserEvent(anonymousUserId, activeQuestion?.id);
    }
  }, [activeQuestion, userId, anonymousUserId]);

  useEffect(() => {
    if (!shouldDisplay) {
      if (settings.display_mode === "TIME") {
        console.log(
          "[MENTO PLUGIN] Displaying the component after " +
            settings.display_after_seconds +
            " seconds."
        );
        const displayAfterSeconds =
          settings.display_after_seconds === 0
            ? 0.5
            : settings.display_after_seconds;
        const timer = setTimeout(() => {
          console.log("[MENTO PLUGIN] Displaying the component");
          setShouldDisplay(true);
        }, displayAfterSeconds * 1000);
        return () => clearTimeout(timer);
      } else if (settings.display_mode === "SCROLL") {
        console.log(
          "[MENTO PLUGIN] Displaying the component after " +
            settings.display_after_scroll_percentage +
            " scroll percentage."
        );
        const handleScroll = () => {
          const scrollPercentage =
            (window.scrollY /
              (document.body.scrollHeight - window.innerHeight)) *
            100;
          if (scrollPercentage >= settings.display_after_scroll_percentage) {
            console.log("[MENTO PLUGIN] Displaying the component.");
            setShouldDisplay(true);
            window.removeEventListener("scroll", handleScroll);
          }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }
    }
  }, [settings, shouldDisplay]);

  const toggleWindowSize = (expand) => {
    if (expand) {
      if (isMobile) {
        setWindowWidth(window.innerWidth * 0.93);
      } else {
        setWindowWidth(400);
      }
    } else {
      setWindowWidth(originalWindowWidth);
    }
  };

  const logUserEvent = async (currentUserId, questionId) => {
    if (!registeringVisitLoading) {
      setRegisteringVisitLoading(true);
      await handlePageVisitFromUser(currentUserId, questionId);
      setRegisteringVisitLoading(false);
    }
  };

  const handleNewActiveQuestion = (q) => {
    setActiveQuestion(q);
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0); // Scroll to the top of the element
    }
  };

  const allowAnonymous = activeQuestion?.panels?.allow_anonymous_answers;
  if (!position) {
    console.log("[MENTO PLUGIN] Position is undefined or null.");
    return null;
  }

  if (userLoading) {
    console.log("[MENTO PLUGIN] User is still loading.");

    if (displayMode !== "plugin") {
      return null;
    } else {
      return null;
    }
  }

  let pluginWrapperStyle = {
    position: "fixed",
    bottom: `${settings.position_padding_bottom}px`,
    right: `${settings.position_padding_horizontal}px`,
    zIndex: 2000,
    fontFamily:
      "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontSize: "small",
  };

  let pluginContentStyle = {
    position: "absolute",
    height: `auto`,
    width: `${windowWidth}px`,
    bottom: `${iconSize + 10}px`,
    left: `-${windowWidth - (iconSize + 10)}px`,
    boxSizing: "border-box",
    borderRadius: `${settings.border_radius}px` || "8px",
    border: `1px solid rgb(98, 98, 98, 0.5)`,
    maxHeight: isMobile ? "70vh" : "500px",
    overflowY: "scroll",
    padding: "12px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 0px 30px 10px rgba(0, 0, 0, 0.2)",
    lineHeight: "1.4",
  };

  if (position === "LEFT") {
    pluginWrapperStyle["left"] = `${settings.position_padding_horizontal}px`;
    pluginWrapperStyle["right"] = "auto";
    pluginContentStyle["left"] = "auto";
    pluginContentStyle["right"] = `-${windowWidth - (iconSize + 10)}px`;
  }

  const isExpanded = windowWidth > originalWindowWidth;

  if (!shouldDisplay || isManuallyHidden) {
    if (displayMode !== "plugin") {
      return null;
    } else {
      return null;
    }
  }

  if (
    displayBox &&
    isExpanded &&
    isMobile &&
    activeQuestion &&
    !!(!!user || allowAnonymous)
  ) {
    //  Alternative display for mobile
    return (
      <FadeIn transitionDuration={100} visible={displayBox && !questionLoading}>
        <div
          style={{ ...pluginWrapperStyle, bottom: 0, right: 0, left: 0 }}
          id="mento-plugin-wrapper-fullscreen"
        >
          <div
            ref={contentRef}
            style={{
              ...pluginContentStyle,
              width: "100%",
              bottom: 0,
              left: 0,
              right: 0,
            }}
            id="mento-plugin-content-fullscreen"
          >
            <MainContentBoxExpanded
              externalUserId={user ? user?.id : undefined}
              userAnswerOptionId={userAnswerOptionId}
              handleCloseClick={() => setDisplayBox(false)}
              handleNewActiveQuestion={handleNewActiveQuestion}
              panelId={panelId ? panelId : undefined}
              question={activeQuestion}
              refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
              toggleWindowSize={toggleWindowSize}
              webpageId={activeWebpageId}
            ></MainContentBoxExpanded>
            <Footer />
          </div>
        </div>
      </FadeIn>
    );
  }

  const commonProps = {
    activeQuestion,
    questionLoading,
    displayBox,
    setDisplayBox,
    user,
    allowAnonymous,
    userAnswerOptionId,
    handleNewActiveQuestion,
    panelId,
    fetchQuestionFromCurrentActiveQuestion,
    toggleWindowSize,
    activeWebpageId,
    windowWidth,
    settings,
    isManuallyHidden,
    setIsManuallyHidden,
    contentRef,
    isMobile,
  };

  return displayMode === "plugin" ? (
    <PluginStart {...commonProps} />
  ) : (
    <EmbeddedStart {...commonProps} />
  );
}

export default Start;
