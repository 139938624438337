import { supabase } from "../utils/Api";

// Consolidated error handler
const handleSupabaseError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  return null;
};

// Cleaned up template string
const defaultQuestionSelect = `
  title,
  published_date,
  description,
  id,
  path,
  statistics_heading,
  enable_feelings,
  link_url,
  image_url,
  vote_end_date,
  panel_id,
  type,
  panels(id, name, image_url, allow_anonymous_answers),
  answers(count),
  answer_options!question_id(
    id, 
    answer_title, 
    answer_option_order, 
    image_url, 
    answers(count)
  )
`.trim();

const defaultQuestionWithWebpageSelect = `
  ${defaultQuestionSelect},
  question_webpage_relationships(webpages(id,link_url,type))
`.trim();

// Simplified fetch user answer
export const fetchUserAnswer = async (questionId, externalUserId) => {
  // const localAnswer = getLocalStorageItem(questionId);

  // if (localAnswer) {
  //   return localAnswer;
  // }

  try {
    const { data, error } = await supabase
      .from("answers")
      .select("answer_option_id")
      .match({ external_user_id: externalUserId, question_id: questionId })
      .single();

    if (error) throw error;
    // setLocalStorageItem(questionId, data?.answer_option_id ?? null);
    return data?.answer_option_id ?? null;
  } catch (error) {
    return handleSupabaseError(error, "fetchUserAnswer");
  }
};

// Simplified fetch user
export const fetchUser = async (userId, secretHash, customerName, panelId) => {
  // const localUser = getLocalStorageItem(`user_${userId}_${panelId}`);

  // if (localUser) {
  //   return localUser;
  // }

  try {
    const { data, error } = await supabase.rpc("handle_external_user", {
      input_external_id: userId,
      input_external_id_hash: secretHash ?? null,
      input_communication_identifier: customerName,
      input_panel_id: panelId,
    });

    if (error) throw error;

    // if (data?.[0]) {
    //   setLocalStorageItem(`user_${userId}_${panelId}`, data[0]);
    // }

    return data?.[0] ?? null;
  } catch (error) {
    return handleSupabaseError(error, "fetchUser");
  }
};

export const fetchPanel = async (panelId) => {
  // const localPanel = getLocalStorageItem(`panel_${panelId}`);

  // if (localPanel) {
  //   return localPanel;
  // }

  try {
    const { data, error } = await supabase
      .from("panels")
      .select("id,allow_anonymous_answers")
      .eq("id", panelId)
      .single();

    if (error) throw error;

    // if (data) {
    //   setLocalStorageItem(`panel_${panelId}`, data);
    // }

    return data ?? null;
  } catch (error) {
    return handleSupabaseError(error, "fetchPanel");
  }
};

// Example of using timeout wrapper
export const fetchPanelPluginSettings = async (panelId) => {
  try {
    const { data, error } = await supabase
      .from("panel_plugin_settings")
      .select(
        `
        id,
        panel_id,
        button_image_url,
        button_background_color,
        accent_color,
        position,
        position_padding_bottom,
        position_padding_horizontal,
        border_radius,
        display_mode,
        display_after_seconds,
        display_after_scroll_percentage,
        display_total_answers_after
      `
      )
      .eq("panel_id", panelId)
      .single();

    if (error) throw error;
    return data ?? null;
  } catch (error) {
    return handleSupabaseError(error, "fetchPanelPluginSettings");
  }
};

export const handlePageVisitFromUser = async (userId, questionId) => {
  if (!userId || !questionId) {
    return false;
  }

  // if (getLocalStorageItem(`visit_${userId}_${questionId}`)) {
  //   return true;
  // }

  const visitQuery = await supabase.functions.invoke(
    "handle-page-visit-from-user",
    {
      method: "POST",
      body: {
        userId: userId,
        questionId,
      },
    }
  );

  const { data, error, status } = await visitQuery;

  // if (
  //   data?.message === "Registered user visit" ||
  //   data?.message === "User visit already registered"
  // ) {
  //   setLocalStorageItem(`visit_${userId}_${questionId}`, true);
  // }

  return true;
};

export const fetchQuestion = async (url, id, panelId) => {
  try {
    const currentDate = new Date().toISOString();
    let query = supabase
      .from("webpages")
      .select(
        `
        id,
        question_webpage_relationships!inner(
          questions!inner(
            ${defaultQuestionWithWebpageSelect}
          )
        )
      `,
        { count: "exact" }
      )
      .lte(
        "question_webpage_relationships.questions.published_date",
        currentDate
      );

    if (id) {
      query = supabase
        .from("questions")
        .select(defaultQuestionWithWebpageSelect, { count: "exact" })
        .eq("id", id)
        .lte("published_date", currentDate);
    } else if (url) {
      const parsedUrl = new URL(url);
      const formattedUrl = parsedUrl.hostname + parsedUrl.pathname;
      query.like("link_url", `%${formattedUrl}`);
    }

    if (panelId) {
      if (id) {
        query.eq("panel_id", panelId);
      } else {
        query.eq("question_webpage_relationships.questions.panel_id", panelId);
      }
    }

    const { data, error } = await query;

    if (error) throw error;

    if (!data?.length) return { question: null, webpageId: null };

    if (id) {
      return { question: data[0] };
    }

    const relationships = data[0].question_webpage_relationships;
    if (relationships?.length > 0 && relationships[0].questions) {
      return {
        question: relationships[0].questions,
        webpageId: data[0].id,
      };
    }

    return { question: null, webpageId: null };
  } catch (error) {
    return handleSupabaseError(error, "fetchQuestion");
  }
};

export const fetchPanelAttributeQuestionsWithAnswers = async (
  externalUserId,
  panelId
) => {
  try {
    const currentDate = new Date().toISOString();
    const { data, error } = await supabase
      .from("questions")
      .select(defaultQuestionSelect, { count: "exact" })
      .eq("type", "PANEL_ATTRIBUTE")
      .eq("panel_id", panelId)
      .lte("published_date", currentDate);

    if (error) throw error;
    return data;
  } catch (error) {
    return handleSupabaseError(
      error,
      "fetchPanelAttributeQuestionsWithAnswers"
    );
  }
};

export const fetchUnAnsweredPanelAttributeQuestion = async (
  externalUserId,
  panelId
) => {
  try {
    const currentDate = new Date().toISOString();
    const { data, error } = await supabase
      .from("questions")
      .select(
        `
          id,
          answer_options!question_id(
            id, 
            answer_title, 
            answer_option_order, 
            image_url
          ),
          title,
          published_date,
          description,
          path,
          statistics_heading,
          enable_feelings,
          link_url,
          image_url,
          vote_end_date,
          panel_id,
          type,
          answers(id,external_user_id)`,
        {
          count: "exact",
        }
      )
      .eq("type", "PANEL_ATTRIBUTE")
      .eq("panel_id", panelId)
      .lte("published_date", currentDate)
      .eq("answers.external_user_id", externalUserId);

    const unAnsweredQuestion = data.find(
      (question) => question.answers.length === 0
    );

    if (error) throw error;
    return unAnsweredQuestion;
  } catch (error) {
    return handleSupabaseError(error, "fetchUnAnsweredPanelAttributeQuestion");
  }
};

export const fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion = async (
  questionId,
  relatedQuestionId,
  externalUserId
) => {
  try {
    const { data, error } = await supabase.rpc(
      "get_answer_options_stats_for_question_and_related_question_v2",
      {
        input_question_id: questionId,
        input_related_question_id: relatedQuestionId,
        input_external_user_id: externalUserId,
        input_user_id: null,
      }
    );

    if (error) throw error;
    return data;
  } catch (error) {
    return handleSupabaseError(error, "fetchAnswerOptionsStatistics");
  }
};

export const getMoreQuestions = async (
  panelId,
  questionId,
  externalUserId,
  page = 1,
  pageSize = 5
) => {
  try {
    const currentDate = new Date().toISOString();
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize - 1;

    const select = `${defaultQuestionWithWebpageSelect}`;

    const { data, error } = await supabase
      .from("questions")
      .select(select, { count: "exact" })
      .eq("panel_id", panelId)
      .eq("type", "QUESTION")
      .neq("id", questionId)
      .order("published_date", { ascending: false })
      .lte("published_date", currentDate)
      .range(startIndex, endIndex);

    if (error) throw error;
    return data ?? [];
  } catch (error) {
    return handleSupabaseError(error, "getMoreQuestions");
  }
};
