import { useCustomerPanelSettings } from "../contexts/CustomerPanelSettingsContext";
import { BOX_GRAY, TEXT_GRAY } from "../utils/theme";
import { Text } from "./ui/Text";

export function Footer() {
  const settings = useCustomerPanelSettings();
  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        boxSizing: "border-box",
        bottom: "0",
        left: "0",
        marginLeft: "-12px",
        marginRight: "-12px",
        marginBottom: "-12px",
        width: "calc(100% + 24px)",
        padding: "12px",
        backgroundColor: BOX_GRAY,
      }}
    >
      <Text variant="small" style={{ display: "flex", alignItems: "center" }}>
        <a
          href="https://mento.social/"
          target="_blank"
          style={{ marginRight: "8px", color: settings.accent_color }}
        >
          Powered by mento.social
        </a>
        <span style={{ color: TEXT_GRAY }}>|</span>
        <a
          href="https://mento.social/faq"
          target="_blank"
          style={{ marginLeft: "8px", color: settings.accent_color }}
        >
          FAQ
        </a>
      </Text>
    </div>
  );
}
