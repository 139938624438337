import React from "react";
import ReactDOM from "react-dom/client";
import packageJson from "../package.json";
import App from "./App";
import "./index.css";

// Create a new div element
let appElement = document.getElementById("mento-plugin");

if (!appElement) {
  // Create a new div element if it doesn't exist
  appElement = document.createElement("div");
  appElement.id = "mento-plugin";
  appElement.setAttribute("data-version", packageJson.version); //
  document.body.appendChild(appElement);
  console.log("[MENTO PLUGIN] Index.js loaded.");
  // Create a root and render the React app inside the new element
  const root = ReactDOM.createRoot(appElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
