import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";

export const IconButton = ({
  icon: Icon,
  onClick,
  color,
  backgroundColor,
  size = 16,
  disableBorder = false,
  style = {},
}) => {
  const settings = useCustomerPanelSettings();

  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        height: `${size}px`,
        width: `${size}px`,
        color: color || settings.accent_color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "content-box",
        padding: disableBorder ? "0px" : "8px",
        borderRadius: disableBorder ? "0px" : "35%",
        backgroundColor: backgroundColor
          ? backgroundColor
          : disableBorder
          ? "transparent"
          : `rgba(${parseInt(
              (color || settings.accent_color).slice(1, 3),
              16
            )}, ${parseInt(
              (color || settings.accent_color).slice(3, 5),
              16
            )}, ${parseInt(
              (color || settings.accent_color).slice(5, 7),
              16
            )}, 0.3)`,
        ...style,
      }}
    >
      {Icon}
    </div>
  );
};
