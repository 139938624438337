import React from "react";
import { Results } from "./Results";
import { Button } from "./ui/Button";

const BlurredResults = ({ onViewResultClick }) => {
  // Mocked data for the Results component
  const mockedData = {
    question: {
      answer_options: [
        { id: 1, answer_title: "Option 1", answers: [{ count: 120 }] },
        { id: 2, answer_title: "Option 2", answers: [{ count: 80 }] },
      ],
      answers: [{ count: 200 }],
    },
    answerOptionId: null,
    filterOnAnswerOptionId: null,
    onAnswerOptionClicked: null,
  };

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Results {...mockedData} />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "70%" }}>
          <Button onClick={onViewResultClick} text="Se resultat" />
        </div>
      </div>
    </div>
  );
};

export default BlurredResults;
