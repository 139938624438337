const BarChart = ({ data }) => {
  // Validate that percentages sum to 100
  const totalPercentage = data.reduce((sum, item) => sum + item.percentage, 0);
  if (Math.round(totalPercentage) !== 100) {
    console.warn("Percentages should sum to 100");
  }

  let currentPosition = 0;

  const containerStyle = {
    width: "100%",
    maxWidth: "32rem", // equivalent to max-w-2xl
  };

  const barContainerStyle = {
    width: "100%",
    height: "64px", // equivalent to h-16
    border: "1px solid #d1d5db", // equivalent to border-gray-300
    borderRadius: "0.5rem", // equivalent to rounded-lg
    overflow: "hidden",
    display: "flex",
  };

  const legendContainerStyle = {
    marginTop: "1rem", // equivalent to mt-4
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem", // equivalent to gap-4
  };

  const legendItemStyle = {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem", // equivalent to gap-2
  };

  const legendColorBoxStyle = {
    width: "1rem", // equivalent to w-4
    height: "1rem", // equivalent to h-4
    borderRadius: "0.25rem", // equivalent to rounded
  };

  const legendLabelStyle = {
    fontSize: "0.875rem", // equivalent to text-sm
    color: "#374151", // equivalent to text-gray-700
  };

  return (
    <div style={containerStyle}>
      {/* Bar Chart */}
      <div style={barContainerStyle}>
        {data.map((item, index) => {
          const width = `${item.percentage}%`;
          const position = currentPosition;
          currentPosition += item.percentage;

          return (
            <div
              key={index}
              style={{
                width,
                backgroundColor: item.color,
                marginLeft: position === 0 ? "0" : undefined,
              }}
            />
          );
        })}
      </div>

      {/* Legend */}
      <div style={legendContainerStyle}>
        {data.map((item, index) => (
          <div key={index} style={legendItemStyle}>
            <div
              style={{
                ...legendColorBoxStyle,
                backgroundColor: item.color,
              }}
            />
            <span style={legendLabelStyle}>
              {item.label} ({item.percentage}%)
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
