import React from "react";
import ReactDOM from "react-dom/client";
import packageJson from "../package.json";
import App from "./App";
import "./index.css";

function initMentoPlugin() {
  console.log("[MENTO PLUGIN] Script loaded.");
  let rendered = false;
  let appEmbeddedElement = document.getElementById("mento-social-embedded");

  if (appEmbeddedElement) {
    // Check if the element has already been activated
    const isActivated =
      appEmbeddedElement.getAttribute("data-mento-actived") === "true";

    if (!isActivated) {
      // Mark the element as activated
      appEmbeddedElement.setAttribute("data-mento-actived", "true");

      let appElement = document.getElementById("mento-plugin");
      if (appElement) {
        console.log("[MENTO PLUGIN] Removing regular plugin.");
        appElement.remove();
      }

      // Create a root and render the React app inside the element
      console.log("[MENTO PLUGIN] Rendering embedded plugin.");

      const root = ReactDOM.createRoot(appEmbeddedElement);
      root.render(
        <React.StrictMode>
          <App embedded={true} />
        </React.StrictMode>
      );
      rendered = true;
      // Remove render from regular plugin
    } else {
      console.log(
        "[MENTO PLUGIN] Embedded element already activated, skipping render."
      );
      let appElement = document.getElementById("mento-plugin");
      if (appElement) {
        console.log("[MENTO PLUGIN] Removing regular plugin.");
        appElement.remove();
      }
      rendered = true;
    }
  } else {
    // Verify that the appElement doesn't exist
    let appElement = document.getElementById("mento-plugin");

    if (!appElement) {
      // Create a new div element if it doesn't exist
      appElement = document.createElement("div");
      appElement.id = "mento-plugin";
      appElement.setAttribute("data-version", packageJson.version); //
      document.body.appendChild(appElement);
      // Create a root and render the React app inside the new element
      console.log("[MENTO PLUGIN] Rendering plugin.");
      const root = ReactDOM.createRoot(appElement);
      root.render(
        <React.StrictMode>
          <App embedded={false} />
        </React.StrictMode>
      );
      rendered = true;
    } else {
      rendered = true;
    }
  }

  const maxRetries = 5;
  let retryCount = 0;
  // If nothing was rendered and we haven't exceeded max retries, try again after 1 second
  if (!rendered && retryCount < maxRetries) {
    console.log(
      `[MENTO PLUGIN] No elements found to render. Retrying in 1 second... (${
        retryCount + 1
      }/${maxRetries})`
    );
    setTimeout(() => {
      initMentoPlugin(retryCount + 1, maxRetries);
    }, 1000);
  } else if (!rendered) {
    console.log(
      "[MENTO PLUGIN] Max retries reached. No elements found to render."
    );
  }
}

setTimeout(() => {
  console.log("[MENTO PLUGIN] Initializing plugin afte 1.5 sec");
  initMentoPlugin();
}, 1500);

// Store the current URL to detect changes
let currentUrl = window.location.href;

// Listen for URL changes in single-page applications
function setupUrlChangeListeners() {
  // Option 2: Use MutationObserver to detect DOM changes that might indicate navigation
  const observer = new MutationObserver((mutations) => {
    if (currentUrl !== window.location.href) {
      console.log("[MENTO PLUGIN] URL changed:", window.location.href);
      currentUrl = window.location.href;

      // Reset activation state to allow re-initialization
      const embedded = document.getElementById("mento-social-embedded");
      // if (embedded) embedded.removeAttribute("data-mento-actived");

      // Re-initialize the plugin
      console.log("[MENTO PLUGIN] Re-initializing plugin.");
      setTimeout(() => {
        console.log("[MENTO PLUGIN] Re-initializing plugin after 1 sec");
        initMentoPlugin();
      }, 1000);
    }
  });

  // Observe changes to the document body
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
}

// Set up URL change listeners
setupUrlChangeListeners();
