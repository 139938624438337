import FadeIn from "react-fade-in/lib/FadeIn";
import { MdOutlineClose } from "react-icons/md";
import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";
import { CLOSE_ICON, MENTO_BG_COLOR } from "../../utils/theme";
import { iconSize } from "../Start";
import { IconButton } from "./IconButton";

const buttonBackground = window.mentoCustomPluginBackground
  ? window.mentoCustomPluginBackground
  : MENTO_BG_COLOR;
const imageBackground = window.mentoCustomPluginImage
  ? window.mentoCustomPluginImage
  : "https://gyrstiqzamltthbjartl.supabase.co/storage/v1/object/public/website/widget-icon.png";

export const PluginButton = ({ click, hideClick, active }) => {
  const settings = useCustomerPanelSettings();
  return (
    <div
      onClick={click}
      id="mento-plugin-button"
      style={{
        backgroundColor: settings.button_background_color || buttonBackground,
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        borderRadius: "50px",
      }}
    >
      <FadeIn
        style={{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
          borderRadius: "50px",
        }}
        delay={50}
        visible={!active}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            zIndex: 5,
          }}
        >
          <IconButton
            icon={<MdOutlineClose />}
            onClick={(e) => {
              e.stopPropagation();
              hideClick();
            }}
            color={"white"}
            backgroundColor={CLOSE_ICON}
            size={12}
          />
        </div>
        <img
          src={settings.button_image_url || imageBackground}
          alt="Widget Icon"
          style={{
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            borderRadius: "50px",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      </FadeIn>
      <FadeIn
        style={{
          width: `${iconSize}px`,
          height: `${iconSize}px`,
          borderRadius: "50px",
        }}
        delay={50}
        visible={active}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            position: "relative",
            top: "-50px",
          }}
        >
          <span
            style={{ color: "white", textAlign: "center", cursor: "pointer" }}
          >
            Stäng
          </span>
        </div>
      </FadeIn>
    </div>
  );
};
