import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { supabase } from "../utils/Api";
import {
  BOX_GRAY,
  BUTTON_TEXT,
  PRIMARY_COLOR,
  TEXT_GRAY,
} from "../utils/theme";
import { Button } from "./ui/Button";

const pageSize = 5;

const getMoreQuestions = async (
  panelId,
  questionId,
  externalUserId,
  page = 1
) => {
  const currentDate = new Date().toISOString();
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize - 1;
  const query = supabase
    .from("questions")
    .select(
      `
      title,
      published_date,
      description,
      enable_feelings,
      id,
      path,
      type,
      link_url,
      vote_end_date,
      panels(id, allow_anonymous_answers),
      answers(count),
      answer_options!question_id(id, answer_title, answer_option_order, image_url, answers(count))
    `,
      { count: "exact" }
    )
    .eq("panel_id", panelId)
    .eq("type", "QUESTION")
    .neq("id", questionId)
    .order("published_date", { ascending: false })
    .lte("published_date", currentDate)
    .range(startIndex, endIndex);

  const { data, error } = await query;
  if (!error) {
    return data;
  } else {
    console.log(error);
    return [];
  }
};

export const MoreQuestions = ({
  question,
  externalUserId,
  panelId,
  handleNewActiveQuestion,
}) => {
  const theme = useCustomerTheme();
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleMoreQuestions = async (extendQuestions) => {
    setLoading(true);
    const items = await getMoreQuestions(
      panelId,
      question.id,
      externalUserId,
      page
    );
    if (extendQuestions) {
      setQuestions([...questions, ...items]);
    } else {
      setQuestions(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panelId && question?.id && page !== 1) {
      handleMoreQuestions(true);
    }
  }, [page]);

  useEffect(() => {
    if (panelId && question?.id) {
      setPage(1);
      handleMoreQuestions(false);
    }
  }, [question, panelId]);

  return (
    <FadeIn>
      <div style={{ marginBottom: "8px", paddingTop: "12px" }}>
        <span
          style={{
            fontSize: "medium",
            fontWeight: "bold",
            color: TEXT_GRAY,
          }}
        >
          Andra har även svarat på
        </span>
      </div>
      <div style={{ minHeight: "140px" }}>
        {questions.length === 0 && !loading && (
          <span style={{ color: TEXT_GRAY }}>
            Vi hittar tyvärr inga fler frågor
          </span>
        )}
        {questions.map((q) => (
          <FadeIn key={q.id}>
            <div
              style={{
                marginBottom: "8px",
                padding: "8px",
                borderRadius: `${theme.border_radius}px` || "8px",
                backgroundColor: BOX_GRAY,
                position: "relative",
              }}
            >
              <p
                onClick={() => handleNewActiveQuestion(q)}
                style={{
                  fontSize: "medium",
                  fontWeight: "bold",
                  maxWidth: "90%",
                  cursor: "pointer",
                  marginBottom: "4px",
                  color: theme.accent_color || PRIMARY_COLOR,
                }}
              >
                {q.title}
              </p>
              <p
                style={{
                  fontSize: "small",
                  marginBottom: "4px",
                  color: TEXT_GRAY,
                }}
              >
                {q.description}
              </p>
            </div>
          </FadeIn>
        ))}

        {questions.length >= page * pageSize && (
          <Button
            onClick={() => setPage(page + 1)}
            text={"Se fler frågor"}
            style={{
              width: "100%",
              borderRadius: `${theme.border_radius}px` || "8px",
              backgroundColor: theme.accent_color || PRIMARY_COLOR,
              color: BUTTON_TEXT,
            }}
          />
        )}
      </div>
    </FadeIn>
  );
};
