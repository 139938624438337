import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerPanelSettings } from "../contexts/CustomerPanelSettingsContext";
import { getMoreQuestions } from "../helpers/primaryApiFunctions";
import { BUTTON_TEXT } from "../utils/theme";
import { Button } from "./ui/Button";
import { QuestionFeedItem } from "./ui/QuestionFeedItem";
import { Text } from "./ui/Text";

export const MoreQuestions = ({
  question,
  externalUserId,
  panelId,
  handleNewActiveQuestion,
}) => {
  const settings = useCustomerPanelSettings();
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);

  const pageSize = 5;

  useEffect(() => {
    const containerElement = document.querySelector(
      ".mento-more-questions-wrapper"
    );
    if (!containerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerElement);
    return () => resizeObserver.disconnect();
  }, []);

  const handleMoreQuestions = async (extendQuestions) => {
    setLoading(true);
    const items = await getMoreQuestions(
      panelId,
      question.id,
      externalUserId,
      page,
      pageSize
    );
    if (extendQuestions) {
      setQuestions([...questions, ...items]);
    } else {
      setQuestions(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panelId && question?.id && page !== 1) {
      handleMoreQuestions(true);
    }
  }, [page]);

  useEffect(() => {
    if (panelId && question?.id) {
      setPage(1);
      handleMoreQuestions(false);
    }
  }, [question, panelId]);

  return (
    <div className="mento-more-questions-wrapper">
      <FadeIn style={{ marginTop: "20px" }}>
        <Text
          variant="subtitle"
          style={{ paddingTop: "12px", marginBottom: "4px" }}
        >
          Andra har även svarat på
        </Text>
        <div
          style={
            containerWidth > 600 ? { gap: "10px" } : { minHeight: "140px" }
          }
        >
          {questions.length === 0 && !loading && (
            <Text color="gray">Vi hittar tyvärr inga fler frågor</Text>
          )}
          <div
            style={
              containerWidth > 600 ? { display: "flow-root", columns: 2 } : {}
            }
          >
            {questions.map((q) => (
              <FadeIn key={q.id}>
                <div
                  style={{
                    minWidth: containerWidth > 600 ? "300px" : "auto",
                  }}
                >
                  <QuestionFeedItem
                    question={q}
                    onClick={handleNewActiveQuestion}
                  />
                </div>
              </FadeIn>
            ))}
          </div>

          {questions.length >= page * pageSize && (
            <Button
              onClick={() => setPage(page + 1)}
              text={"Se fler frågor"}
              style={{
                width: "100%",
                marginTop: "10px",
                borderRadius: `${settings.border_radius}px` || "8px",
                backgroundColor: settings.accent_color,
                color: BUTTON_TEXT,
              }}
            />
          )}
        </div>
      </FadeIn>
    </div>
  );
};
