import dayjs from "dayjs";
import "dayjs/locale/sv";
import { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";
import { fetchPanelAttributeQuestionsWithAnswers } from "../../helpers/primaryApiFunctions";
import { formatTimeAgo } from "../../utils/formatTimeAgo";
import { isValidUrl } from "../../utils/imageHelpers";
import { AnswerQuestion } from "../AnswerQuestion";
import { FeelingResults } from "../FeelingResults";
import { ContentWrapper } from "../layout/ContentWrapper";
import { MoreQuestionsEmbedded } from "../MoreQuestionsEmbedded";
import { ProfileQuestionAnswerOptions } from "../ProfileQuestionAnswerOptions";
import { Results } from "../Results";
import { Badge } from "../ui/Badge";
import { Button } from "../ui/Button";
import { Text } from "../ui/Text";

dayjs.locale("sv");

export const MainContentEmbedded = ({
  question,
  externalUserId,
  userAnswerOptionId,
  panelId,
  refreshQuestion,
  handleCloseClick,
  handleNewActiveQuestion,
  webpageId,
  isMobile,
}) => {
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  const [filterOnAnswerOption, setFilterOnAnswerOption] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [displayMode, setDisplayMode] = useState("ANSWER_QUESTION");

  useEffect(() => {
    setViewMore(false);
    const mostAnsweredAnswerOption = question.answer_options.sort(
      (a, b) => (b.answers[0]?.count ?? 0) - (a.answers[0]?.count ?? 0)
    )[0];
    setFilterOnAnswerOption(mostAnsweredAnswerOption);
  }, [question]);

  useEffect(() => {
    setSelectedAnswerOption(userAnswerOptionId);
  }, [userAnswerOptionId]);

  useEffect(() => {
    const isPastVoteEndDate =
      question.vote_end_date && new Date(question.vote_end_date) < new Date();

    if (selectedAnswerOption || isPastVoteEndDate) {
      const resultPage = question?.question_webpage_relationships?.find(
        (qwr) => qwr.webpages?.type === "RESULT_PAGE"
      )?.webpages;

      if (resultPage) {
        // Check if current page is the result page
        const parsedUrl = new URL(window.location.href);
        const currentUrlFormats = [
          parsedUrl.hostname + parsedUrl.pathname, // hostname + path
          window.location.href, // full URL with params
          parsedUrl.origin + parsedUrl.pathname, // origin + path (no params)
          parsedUrl.hostname, // hostname only (match entire domain)
          parsedUrl.pathname, // path only (match any domain)
          parsedUrl.host + parsedUrl.pathname, // host (with port) + path
          parsedUrl.href.split("?")[0], // full URL before any query string
        ];

        // Check if result page URL matches any of the current URL formats

        const isResultPage = currentUrlFormats.some(
          (format) => resultPage.link_url && resultPage.link_url === format
        );

        setDisplayMode(isResultPage ? "RESULTS" : "RESULTS_CTA");
      } else {
        setDisplayMode("RESULTS");
      }
    } else {
      setDisplayMode("ANSWER_QUESTION");
    }
  }, [selectedAnswerOption, question]);

  const isPastVoteEndDate =
    question.vote_end_date && new Date(question.vote_end_date) < new Date();

  return (
    <ContentWrapper style={{ position: "relative", padding: "0px" }}>
      <img
        src="https://gyrstiqzamltthbjartl.supabase.co/storage/v1/object/public/website//Mento%20logo%20no%20beta.png"
        alt="Loading..."
        style={{
          maxWidth: "140px",
          position: "absolute",
          top: "-25px",
          right: "-15px",
        }}
      />
      <div>
        <QuestionHeaderEmbedded
          question={question}
          isPastVoteEndDate={isPastVoteEndDate}
          isMobile={isMobile}
        />
        {displayMode === "RESULTS" && (
          <ResultsEmbedded
            question={question}
            selectedAnswerOption={selectedAnswerOption}
            setSelectedAnswerOption={setSelectedAnswerOption}
            filterOnAnswerOption={filterOnAnswerOption}
            setFilterOnAnswerOption={setFilterOnAnswerOption}
            externalUserId={externalUserId}
            panelId={panelId}
            webpageId={webpageId}
            userAnswerOptionId={userAnswerOptionId}
            handleNewActiveQuestion={handleNewActiveQuestion}
            refreshQuestion={refreshQuestion}
            isMobile={isMobile}
          />
        )}
        {displayMode === "ANSWER_QUESTION" && (
          <AnswerQuestionEmbedded
            question={question}
            isPastVoteEndDate={isPastVoteEndDate}
            setSelectedAnswerOption={setSelectedAnswerOption}
            refreshQuestion={refreshQuestion}
            externalUserId={externalUserId}
            panelId={panelId}
            webpageId={webpageId}
            isMobile={isMobile}
          />
        )}
        {displayMode === "RESULTS_CTA" && (
          <ResultsCtaEmbedded question={question} isMobile={isMobile} />
        )}
      </div>
    </ContentWrapper>
  );
};

const QuestionHeaderEmbedded = ({ question, isPastVoteEndDate, isMobile }) => {
  const settings = useCustomerPanelSettings();
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "10px",
        marginBottom: isMobile ? "8px" : "16px",
        paddingTop: isMobile ? "36px" : "12px",
      }}
    >
      {question.image_url && isValidUrl(question.image_url) && (
        <div
          style={{
            position: "relative",
            maxWidth: isMobile ? "100%" : "240px",
            minWidth: isMobile ? "100%" : "140px",
            minHeight: isMobile ? "90px" : "150px", // Minimum height for placeholder
            borderRadius: `${settings.border_radius}px` || "8px",
            backgroundColor: "#f0f0f0", // Light gray placeholder
            overflow: "hidden",
          }}
        >
          {!imageLoaded && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#888",
              }}
            >
              Loading...
            </div>
          )}
          <img
            src={question.image_url}
            alt={question.title}
            onLoad={() => setImageLoaded(true)}
            style={{
              maxWidth: "100%",
              height: "auto",
              objectFit: "cover",
              borderRadius: `${settings.border_radius}px` || "8px",
              opacity: imageLoaded ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Text
          variant="title"
          style={{ color: settings.accent_color, fontSize: "x-large" }}
        >
          {question.title}
        </Text>
        <Text variant="body" color="gray">
          {question.description}
        </Text>
        <div style={{ display: "flex", gap: "4px" }}>
          {question?.published_date && (
            <Badge>
              <Text variant="small">
                {formatTimeAgo(question.published_date)}
              </Text>
            </Badge>
          )}
          <Badge>
            <Text variant="small">
              {question.answers?.[0]?.count >=
              settings.display_total_answers_after
                ? question.answers?.[0]?.count + " svar"
                : "Under " + settings.display_total_answers_after + " svar"}
            </Text>
          </Badge>
        </div>
        {isPastVoteEndDate && (
          <Text variant="small" color="gray" style={{ fontStyle: "italic" }}>
            Tiden för att svara på denna fråga löpte ut{" "}
            {dayjs(new Date(question.vote_end_date)).format(
              "DD MMMM YYYY HH:mm"
            )}
          </Text>
        )}
      </div>
    </div>
  );
};

const GridBox = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "8px",
        boxSizing: "border-box",
      }}
    >
      {children}
    </div>
  );
};

const ResultsEmbedded = ({
  question,
  selectedAnswerOption,
  setSelectedAnswerOption,
  filterOnAnswerOption,
  setFilterOnAnswerOption,
  externalUserId,
  panelId,
  webpageId,
  userAnswerOptionId,
  handleNewActiveQuestion,
  refreshQuestion,
  isMobile,
}) => {
  const settings = useCustomerPanelSettings();
  const wrapperStyle = isMobile
    ? {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "2px",
      }
    : {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
        gap: "16px",
        width: "100%",
      };

  return (
    <>
      <div style={wrapperStyle}>
        <GridBox>
          <Results
            question={question}
            answerOptionId={selectedAnswerOption}
            filterOnAnswerOptionId={
              question.enable_feelings
                ? filterOnAnswerOption
                  ? filterOnAnswerOption.id
                  : null
                : null
            }
            onAnswerOptionClicked={
              question.enable_feelings
                ? (aoId) => {
                    if (
                      filterOnAnswerOption &&
                      filterOnAnswerOption.id === aoId
                    ) {
                      setFilterOnAnswerOption(null);
                    } else {
                      const answerOption = question.answer_options.find(
                        (ao) => ao.id === aoId
                      );
                      setFilterOnAnswerOption(answerOption);
                    }
                  }
                : null
            }
          />
        </GridBox>
        <GridBox>
          <MoreQuestionsEmbedded
            handleNewActiveQuestion={handleNewActiveQuestion}
            question={question}
            externalUserId={externalUserId}
            panelId={panelId ? panelId : undefined}
            isMobile={isMobile}
          />
        </GridBox>
      </div>

      <div style={{ marginTop: "24px", marginLeft: "8px" }}>
        <Text
          variant="title"
          style={{
            color: settings.accent_color,
            fontSize: "large",
          }}
        >
          Djupdyk i resultatet
        </Text>
      </div>
      <div style={wrapperStyle}>
        {question.enable_feelings && (
          <GridBox>
            <FeelingResults
              question={question}
              filterOnAnswerOption={filterOnAnswerOption}
            />
          </GridBox>
        )}
        {externalUserId && (
          <ProfileQuestionsEmbedded
            originalQuestionId={question.id}
            externalUserId={externalUserId}
            panelId={panelId ? panelId : undefined}
            webpageId={webpageId}
            userAnswerOptionId={userAnswerOptionId}
            enableFeelings={question.enable_feelings}
            isMobile={isMobile}
          />
        )}
      </div>
    </>
  );
};

const AnswerQuestionEmbedded = ({
  question,
  isPastVoteEndDate,
  setSelectedAnswerOption,
  refreshQuestion,
  externalUserId,
  panelId,
  webpageId,
  isMobile,
}) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <AnswerQuestion
          skipFeeling={!question.enable_feelings}
          externalUserId={externalUserId ? externalUserId : undefined}
          onClick={(ao) => {
            refreshQuestion();
            setSelectedAnswerOption(ao);
          }}
          question={question}
          webpageId={webpageId}
          panelId={panelId}
          isMobile={isMobile}
        />
      </div>
    </>
  );
};

export const ProfileQuestionsEmbedded = ({
  originalQuestionId,
  externalUserId,
  panelId,
  webpageId,
  userAnswerOptionId,
  enableFeelings,
  isMobile,
}) => {
  const [profileQuestions, setProfileQuestions] = useState([]);
  const [showAllProfileBoxes, setShowAllProfileBoxes] = useState(false);
  const [numberOfProfileBoxes, setNumberOfProfileBoxes] = useState(0);
  const settings = useCustomerPanelSettings();

  useEffect(() => {
    const fetchQuestions = async () => {
      const questions = await fetchPanelAttributeQuestionsWithAnswers(
        externalUserId,
        panelId
      );
      setProfileQuestions(questions || []);
    };

    fetchQuestions();
  }, [externalUserId]);

  useEffect(() => {
    let boxesToShow = 0;

    if (showAllProfileBoxes) {
      boxesToShow = 10;
    } else if (isMobile) {
      boxesToShow = 0;
    } else if (enableFeelings) {
      boxesToShow = 1;
    } else {
      boxesToShow = 2;
    }
    setNumberOfProfileBoxes(boxesToShow);
  }, [isMobile, showAllProfileBoxes, enableFeelings]);

  return (
    <>
      {profileQuestions.slice(0, numberOfProfileBoxes).map((question) => (
        <GridBox key={question.id}>
          <div
            style={{
              position: "relative",
              borderRadius: settings.border_radius,
            }}
          >
            <Text
              variant="subtitle"
              style={{ paddingTop: "12px", marginBottom: "4px" }}
            >
              {question?.statistics_heading
                ? `Resultat fördelat på ${question?.statistics_heading}`
                : question?.title}
            </Text>
            {/* )} */}
          </div>
          <FadeIn>
            <ProfileQuestionAnswerOptions
              userOriginalQuestionAnswerOptionId={userAnswerOptionId}
              externalUserId={externalUserId}
              question={question}
              originalQuestionId={originalQuestionId}
              webpageId={webpageId}
            />
          </FadeIn>
        </GridBox>
      ))}
      {profileQuestions?.length > numberOfProfileBoxes && (
        <Button
          text={"Se mer"}
          style={{ width: "100%", marginTop: "8px" }}
          onClick={() => setShowAllProfileBoxes(true)}
        />
      )}
    </>
  );
};

const ResultsCtaEmbedded = ({ question, isMobile }) => {
  const settings = useCustomerPanelSettings();
  const resultPage = question?.question_webpage_relationships?.find(
    (qwr) => qwr.webpages?.type === "RESULT_PAGE"
  )?.webpages;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "24px",
        gap: "16px",
        textAlign: "center",
      }}
    >
      <Text variant="title" style={{ color: settings.accent_color }}>
        Tack för ditt svar!
      </Text>
      <Text variant="body">
        Klicka på knappen nedan för att se analysem och se vad alla har svarat.
      </Text>
      <Button
        text="Se resultat"
        style={{
          minWidth: isMobile ? "100%" : "200px",
          backgroundColor: settings.accent_color,
        }}
        href={resultPage?.link_url}
      />
    </div>
  );
};
