import React, { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerPanelSettings } from "../contexts/CustomerPanelSettingsContext";
import { CustomCTA } from "./CustomCTA";
import { Footer } from "./Footer";
import { MainContentEmbedded } from "./embedded/MainContentEmbedded";

function EmbeddedStart({
  activeQuestion,
  questionLoading,
  displayBox,
  user,
  allowAnonymous,
  userAnswerOptionId,
  handleNewActiveQuestion,
  panelId,
  fetchQuestionFromCurrentActiveQuestion,
  toggleWindowSize,
  activeWebpageId,
  contentRef,
}) {
  const settings = useCustomerPanelSettings();
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const containerElement = document.querySelector(
      ".mento-embedded-content-wrapper"
    );

    if (!containerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerElement);
    return () => resizeObserver.disconnect();
  }, []);

  const isMobile = containerWidth < 600;

  const embedStyle = {
    width: "100%",
    boxSizing: "border-box",
    margin: "20px auto",
    padding: isMobile ? "8px" : "20px",
    borderRadius: settings.borderRadius,
  };

  if (!activeQuestion) {
    return null;
  }

  return (
    <FadeIn visible={!questionLoading}>
      <div
        style={embedStyle}
        className="mento-embedded-content-wrapper"
        ref={contentRef}
      >
        {!user && !allowAnonymous ? (
          <CustomCTA question={activeQuestion} />
        ) : (
          <MainContentEmbedded
            externalUserId={user?.id}
            userAnswerOptionId={userAnswerOptionId}
            handleNewActiveQuestion={handleNewActiveQuestion}
            panelId={panelId}
            question={activeQuestion}
            refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
            toggleWindowSize={toggleWindowSize}
            webpageId={activeWebpageId}
            embedded={true}
            isMobile={isMobile}
          />
        )}
        <Footer />
      </div>
    </FadeIn>
  );
}

export default EmbeddedStart;
