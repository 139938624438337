import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";

export const ContentWrapper = ({ children, style = {} }) => {
  const settings = useCustomerPanelSettings();

  return (
    <div
      style={{
        borderRadius: `${settings.border_radius}px` || "8px",
        padding: "10px",
        marginBottom: "5px",
        fontSize: "medium",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
