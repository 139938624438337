import { CiCircleInfo } from "react-icons/ci";
import { formatAnswerOptions } from "../utils/formatAnswerOptions";
import { SELECTED_TEXT } from "../utils/theme";
import { Card } from "./ui/Card";
import ResultItem from "./ui/ResultItem";
import { Text } from "./ui/Text";

export const Results = ({
  question,
  answerOptionId,
  filterOnAnswerOptionId,
  onAnswerOptionClicked,
}) => {
  const items = formatAnswerOptions(
    question.answer_options ?? [],
    question?.answers[0]?.count ?? 0,
    true
  );

  return (
    <div style={{ height: "100%" }}>
      <Text
        variant="subtitle"
        style={{ paddingTop: "12px", marginBottom: "4px" }}
      >
        Resultat
      </Text>
      <Card style={{ height: "100%" }}>
        {items.map((item) => (
          <ResultItem
            key={item.id}
            item={item}
            filterOnAnswerOptionId={filterOnAnswerOptionId}
            answerOptionId={answerOptionId}
            onAnswerOptionClicked={onAnswerOptionClicked}
          />
        ))}
        {onAnswerOptionClicked && (
          <span
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              color: SELECTED_TEXT,
              display: "flex",
              alignItems: "center",
            }}
          >
            <CiCircleInfo /> Klicka på ett alternativ för att filtrera på det
          </span>
        )}
      </Card>
    </div>
  );
};
