import React from "react";

import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";
import { Card } from "./Card";
import { Text } from "./Text";

export const QuestionFeedItem = ({ question, onClick, maxHeight }) => {
  const settings = useCustomerPanelSettings();

  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
    return `${diffInDays} dagar sedan`;
  };

  return (
    <Card
      onClick={() => onClick(question)}
      style={{
        marginBottom: "12px",
        padding: "12px",
        cursor: "pointer",
        maxHeight: maxHeight,
        overflow: "scroll",
      }}
    >
      <div style={{ marginBottom: "8px" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            {question?.published_date && (
              <Text
                variant="subtitle"
                style={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {formatTimeAgo(question.published_date)}
              </Text>
            )}

            <Text variant="small" color="gray">
              {question?.answers?.[0]?.count >=
              settings.display_total_answers_after
                ? question?.answers?.[0]?.count + " svar"
                : "Under " + settings.display_total_answers_after + " svar"}
            </Text>
          </div>
        </div>
        <Text
          variant="subtitle"
          onClick={() => onClick(question)}
          style={{
            fontWeight: "bold",
            color: settings.accent_color,
            marginBottom: "4px",
            cursor: "pointer",
          }}
        >
          {question?.title}
        </Text>
        {question?.description && (
          <>
            <br />
            <Text color="gray">{question?.description}</Text>
          </>
        )}
      </div>

      {question.image_url && (
        <img
          src={question.image_url}
          alt={question.title}
          onClick={(e) => {
            e.stopPropagation();
            e.target.style.maxHeight = e.target.style.maxHeight
              ? null
              : "180px";
          }}
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "180px",
            objectFit: "cover",
            borderRadius: `${settings.border_radius}px` || "8px",
            borderBottomRightRadius: settings.border_radius,
            borderBottomLeftRadius: settings.border_radius,
            cursor: "pointer",
          }}
        />
      )}
    </Card>
  );
};
