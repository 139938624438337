import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { fetchPanelAttributeQuestionsWithAnswers } from "../helpers/primaryApiFunctions";
import { PRIMARY_COLOR } from "../utils/theme";
import { ProfileQuestionAnswerOptions } from "./ProfileQuestionAnswerOptions";

export const ProfileQuestions = ({
  originalQuestionId,
  externalUserId,
  panelId,
  webpageId,
}) => {
  const [profileQuestions, setProfileQuestions] = useState([]);
  const theme = useCustomerTheme();

  useEffect(() => {
    const fetchQuestions = async () => {
      const questions = await fetchPanelAttributeQuestionsWithAnswers(
        externalUserId,
        panelId
      );
      setProfileQuestions(questions || []);
    };

    fetchQuestions();
  }, [externalUserId]);

  return (
    <div>
      {profileQuestions.map((question) => (
        <div key={question.id} style={{ marginTop: "16px" }}>
          <span
            style={{
              fontSize: "medium",
              fontWeight: "bold",
              color: theme.accent_color || PRIMARY_COLOR,
            }}
          >
            {question?.statistics_heading
              ? `Resultat fördelat på ${question?.statistics_heading}`
              : question?.title}
          </span>
          <FadeIn>
            <ProfileQuestionAnswerOptions
              externalUserId={externalUserId}
              question={question}
              originalQuestionId={originalQuestionId}
              webpageId={webpageId}
            />
          </FadeIn>
        </div>
      ))}
    </div>
  );
};
