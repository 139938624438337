import React from "react";
import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";

export const Badge = ({ children, color = "default", style = {} }) => {
  const settings = useCustomerPanelSettings();

  const getBackgroundColor = () => {
    switch (color) {
      case "primary":
        return settings.accent_color;
      case "gray":
        return "rgb(210, 210, 210)";
      default:
        return "#f0f0f0";
    }
  };

  const getTextColor = () => {
    switch (color) {
      case "primary":
        return "#ffffff";
      case "gray":
        return "#666666";
      default:
        return "#666666";
    }
  };

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        padding: "2px 8px",
        borderRadius: settings.border_radius,
        fontSize: "12px",
        fontWeight: "500",
        backgroundColor: getBackgroundColor(),
        color: getTextColor(),
        ...style,
      }}
    >
      {children}
    </div>
  );
};
