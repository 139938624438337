import { MD5 } from "crypto-js";
import { useEffect, useState } from "react";
import "./App.css";
import Start from "./components/Start";
import { CustomerPanelSettingsProvider } from "./contexts/CustomerPanelSettingsContext";

function App({ embedded }) {
  const [activeLinks, setActiveLinks] = useState(null);
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    fetch(process.env.REACT_APP_ACTIVE_LINKS_LINK)
      .then((res) => res.json())
      .then((data) => setActiveLinks(data))
      .catch((err) => {
        console.error(err);
        setEnabled(true);
      });
  }, []);

  useEffect(() => {
    if (!activeLinks) return;

    const parsedUrl = new URL(window.location.href);

    const urlFormats = [
      parsedUrl.hostname + parsedUrl.pathname, // hostname + path
      window.location.href, // full URL with params
      parsedUrl.origin + parsedUrl.pathname, // origin + path (no params)
      parsedUrl.hostname, // hostname only (match entire domain)
      parsedUrl.pathname, // path only (match any domain)
      parsedUrl.host + parsedUrl.pathname, // host (with port) + path
      parsedUrl.href.split("?")[0], // full URL before any query string
    ];

    // Check if any URL format is in activeLinks
    const isEnabled = urlFormats.some(
      (url) => activeLinks[MD5(url).toString()]
    );

    if (isEnabled) setEnabled(true);
  }, [activeLinks]);

  if (
    !window.mentoPanelId ||
    !(window.mentoOrganizationName || window.mentoOrganisationName)
  ) {
    return null;
  }

  return enabled ? (
    <CustomerPanelSettingsProvider panelId={window.mentoPanelId}>
      <Start
        userId={window.mentoUserId}
        panelId={window.mentoPanelId}
        customerName={
          window.mentoOrganizationName || window.mentoOrganisationName
        }
        secretHash={window.mentoSecretHash}
        displayMode={embedded ? "embedded" : "plugin"}
      ></Start>
    </CustomerPanelSettingsProvider>
  ) : null;
}

export default App;
