import { useEffect, useRef, useState } from "react";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { useCustomerPanelSettings } from "../contexts/CustomerPanelSettingsContext";
import {
  fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion,
  fetchUserAnswer,
} from "../helpers/primaryApiFunctions";
import { calculatePercentage } from "../utils/formatAnswerOptions";
import { AnswerQuestion } from "./AnswerQuestion";
import BlurredResults from "./BlurredResults";
import { ContentWrapper } from "./layout/ContentWrapper";
import { Card } from "./ui/Card";
import { IconButton } from "./ui/IconButton";
import { ImageOverlay } from "./ui/ImageOverlay";
import ResultItem from "./ui/ResultItem";
import { Text } from "./ui/Text";
import BarChart from "./ui/graphs/BarChart";

export const ProfileQuestionAnswerOptions = ({
  question,
  originalQuestionId,
  externalUserId,
  webpageId,
  userOriginalQuestionAnswerOptionId,
  displayType = "text",
}) => {
  const [formattedAnswerOptions, setFormattedAnswerOptions] = useState([]);
  const [userAnswerOptionId, setUserAnswerOptionId] = useState(null);
  const [blurResult, setBlurResult] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const settings = useCustomerPanelSettings();
  const scrollRef = useRef(null);

  useEffect(() => {
    // Todo: Check if the current user has answered. If yes, fetchAnswerOptionsStatistics and display result. If no, display answer question.
    const checkIfUserHasAnswered = async () => {
      const data = await fetchUserAnswer(question.id, externalUserId);
      setUserAnswerOptionId(data);
    };
    checkIfUserHasAnswered();
  }, [externalUserId, question.id]);

  useEffect(() => {
    const containerElement = document.querySelector(
      ".mento-profile-question-answer-options-wrapper"
    );
    if (!containerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerElement);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const fetchAnswerOptionsStatistics = async () => {
      const data =
        await fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion(
          originalQuestionId,
          question.id,
          externalUserId
        );

      if (data) {
        const formattedData = data.reduce((acc, item) => {
          let existingOption = acc.find(
            (option) =>
              option.related_answer_option_id === item.related_answer_option_id
          );

          if (!existingOption) {
            existingOption = {
              related_answer_option_id: item.related_answer_option_id,
              related_answer_title: item.related_answer_title,
              related_answer_image_url: item.related_answer_image_url,
              items: [],
              totalAmount: 0,
            };
            acc.push(existingOption);
          }

          existingOption.totalAmount += item.amount;
          existingOption.items.push({
            id: item.answer_option_id,
            text: item.answer_title,
            image_url: item.related_answer_image_url,
            amount: item.amount,
            selected: item.selected,
          });
          return acc;
        }, []);
        formattedData.forEach((option) => {
          option.items = option.items.map((item) => ({
            ...item,
            percentage: calculatePercentage(option.totalAmount, item.amount),
          }));
        });
        setFormattedAnswerOptions(
          formattedData.sort((a, b) => b.totalAmount - a.totalAmount)
        );
      }
    };

    if (userAnswerOptionId) {
      fetchAnswerOptionsStatistics();
    }
  }, [userAnswerOptionId, originalQuestionId]);

  const scrollContainer = (direction) => {
    if (scrollRef.current) {
      const scrollAmount =
        containerWidth > 600
          ? containerWidth / 3 // Scroll one item width when showing 3 items
          : 200; // Original scroll amount for mobile
      scrollRef.current.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const data = [
    { label: "Complete", percentage: 45, color: "#4CAF50" },
    { label: "In Progress", percentage: 35, color: "#FFC107" },
    { label: "Not Started", percentage: 20, color: "#F44336" },
  ];

  return (
    <div className="mento-profile-question-answer-options-wrapper">
      {userAnswerOptionId ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "-15px",
            marginRight: "-15px",
          }}
        >
          <IconButton
            icon={<TfiAngleLeft />}
            onClick={() => scrollContainer(-1)}
            color={settings.accent_color}
            disableBorder={true}
            style={{ marginRight: "3px" }}
          />
          <div
            className="mento-scroll-container"
            style={{
              display: "flex",
              overflowX: "scroll",
              scrollSnapType: "x mandatory",
              width: "100%",
            }}
            ref={scrollRef}
          >
            {formattedAnswerOptions.map((fao) => (
              <div
                key={fao.related_answer_option_id}
                style={{
                  flex: "0 0 auto",
                  scrollSnapAlign: "start",
                  width: containerWidth > 750 ? "calc(33.33% - 10px)" : "100%",
                  marginRight: "10px",
                }}
              >
                <ImageOverlay
                  style={{ marginBottom: "0px" }}
                  imageUrl={fao.related_answer_image_url}
                  title={
                    fao.related_answer_title +
                    (fao.related_answer_option_id === userAnswerOptionId
                      ? "(Ditt svar)"
                      : "")
                  }
                  paddingTop="110px"
                  disableBorderBottom={true}
                />
                <Card
                  style={{
                    paddingTop: "0px",
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px",
                  }}
                >
                  {displayType === "text" ? (
                    fao.items.map((item, index) => (
                      <div key={index}>
                        <ResultItem
                          item={item}
                          answerOptionId={userOriginalQuestionAnswerOptionId}
                        />
                      </div>
                    ))
                  ) : (
                    <BarChart data={data} />
                  )}
                </Card>
              </div>
            ))}
          </div>
          <IconButton
            icon={<TfiAngleRight />}
            disableBorder={true}
            onClick={() => scrollContainer(1)}
            color={settings.accent_color}
            style={{ marginLeft: "3px" }}
          />
        </div>
      ) : blurResult ? (
        <BlurredResults onViewResultClick={() => setBlurResult(false)} />
      ) : (
        <ContentWrapper style={{ padding: "0px" }}>
          <Text variant="subtitle" style={{ fontWeight: "bold" }}>
            {question.title}
          </Text>
          <AnswerQuestion
            webpageId={webpageId}
            question={question}
            externalUserId={externalUserId}
            onClick={(answerOptionId) => setUserAnswerOptionId(answerOptionId)}
            skipFeeling
          />
        </ContentWrapper>
      )}
    </div>
  );
};
