import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { getLocalStorageItem } from "../utils/localstorage";
import { PRIMARY_COLOR, TEXT_GRAY } from "../utils/theme";
import { AnswerQuestion } from "./AnswerQuestion";
import { Results } from "./Results";
export const MainContentBox = ({
  question,
  externalUserId,
  userAnswerOptionId,
  refreshQuestion,
  toggleWindowSize,
  webpageId,
}) => {
  const theme = useCustomerTheme();
  const [selectedAnswerOption, setSelectedAnswerOption] =
    useState(userAnswerOptionId);

  const updateSelectedAnswerOptionFromLocalStorage = () => {
    const answerOption = getLocalStorageItem(question.id);
    setSelectedAnswerOption(answerOption ?? null);
  };

  const updateSelectedAnswerOption = () => {
    setSelectedAnswerOption(userAnswerOptionId);
  };

  useEffect(() => {
    if (externalUserId && question) {
      updateSelectedAnswerOption();
    } else {
      updateSelectedAnswerOptionFromLocalStorage();
    }
  }, [question, externalUserId, userAnswerOptionId]);

  const isPastVoteEndDate =
    question.vote_end_date && new Date(question.vote_end_date) < new Date();

  return (
    <div style={{ position: "relative", paddingBottom: "8px" }}>
      <div style={{ marginBottom: "16px", paddingTop: "16px" }}>
        <span
          style={{
            fontSize: "large",
            fontWeight: "bold",
            color: theme.accent_color || PRIMARY_COLOR,
          }}
        >
          {question.title}
        </span>
        <p style={{ fontSize: "medium", color: TEXT_GRAY }}>
          {question.description}
        </p>
        {isPastVoteEndDate && (
          <p
            style={{
              fontSize: "small",
              fontStyle: "italic",
              color: TEXT_GRAY,
            }}
          >
            Tiden för att svara på denna fråga löpte ut{" "}
            {dayjs(new Date(question.vote_end_date)).format(
              "DD MMMM YYYY HH:mm"
            )}
          </p>
        )}
      </div>
      {selectedAnswerOption || isPastVoteEndDate ? (
        <div>
          <Results question={question} answerOptionId={selectedAnswerOption} />
          <span
            style={{
              color: theme.accent_color || PRIMARY_COLOR,
              cursor: "pointer",
            }}
            onClick={() => toggleWindowSize(true)}
          >
            Se mer
          </span>
          {/* <InsightHook
            question={question}
            onExpandClick={() => toggleWindowSize(true)}
          ></InsightHook> */}
          {/* <PanelQuestions panelId={question.panelId} questionId={question.id}></PanelQuestions> */}
        </div>
      ) : (
        <AnswerQuestion
          skipFeeling={!question.enable_feelings}
          externalUserId={externalUserId ? externalUserId : undefined}
          onClick={(ao) => {
            toggleWindowSize(true);
            setSelectedAnswerOption(ao);
          }}
          question={question}
          handleAnswer={refreshQuestion}
          webpageId={webpageId}
        />
      )}
    </div>
  );
};
