import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";
import { isValidUrl } from "../../utils/imageHelpers";
import { Text } from "./Text";

export const ImageOverlay = ({
  imageUrl,
  title,
  description,
  paddingTop = "200px",
  children,
  disableBorderBottom = false,
  style = {},
  titleStyle = {},
  descriptionStyle = {},
  coverOnly = false,
}) => {
  const settings = useCustomerPanelSettings();

  return (
    <div
      style={{
        position: "relative",
        marginBottom: "8px",
        paddingTop: paddingTop,
        borderRadius: settings.border_radius,
        borderBottomRightRadius: disableBorderBottom
          ? "0px"
          : settings.border_radius,
        borderBottomLeftRadius: disableBorderBottom
          ? "0px"
          : settings.border_radius,
        overflow: "hidden",
        ...style,
      }}
    >
      {isValidUrl(imageUrl) ? (
        <img
          src={imageUrl}
          alt={title}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: `${settings.border_radius}px` || "8px",
            borderBottomRightRadius: disableBorderBottom
              ? "0px"
              : settings.border_radius,
            borderBottomLeftRadius: disableBorderBottom
              ? "0px"
              : settings.border_radius,
          }}
        />
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)",
            borderRadius: `${settings.border_radius}px` || "8px",
            borderBottomRightRadius: disableBorderBottom
              ? "0px"
              : settings.border_radius,
            borderBottomLeftRadius: disableBorderBottom
              ? "0px"
              : settings.border_radius,
          }}
        />
      )}
      <div
        style={{
          position: coverOnly ? "relative" : "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          background: "linear-gradient(transparent, rgba(0, 0, 0, 0.7))",
          padding: "20px",
          borderRadius: settings.border_radius,
          borderBottomRightRadius: disableBorderBottom
            ? "0px"
            : settings.border_radius,
          borderBottomLeftRadius: disableBorderBottom
            ? "0px"
            : settings.border_radius,
        }}
      >
        {title && (
          <Text
            variant="title"
            style={{
              color: "#ffffff",
              textShadow: "0px 0px 12px rgba(0, 0, 0, 0.9)",
              marginBottom: description ? "8px" : 0,
              ...titleStyle,
            }}
          >
            {title}
          </Text>
        )}
        {description && (
          <>
            <br />
            <Text
              variant="body"
              style={{
                color: "#ffffff",
                textShadow: "0px 0px 12px rgba(0, 0, 0, 0.9)",
                margin: 0,
                ...descriptionStyle,
              }}
            >
              {description}
            </Text>
          </>
        )}
        {children}
      </div>
    </div>
  );
};
