import { useCustomerPanelSettings } from "../../contexts/CustomerPanelSettingsContext";
import { BOX_GRAY } from "../../utils/theme";

export const Card = ({ children, style = {}, onClick = () => {} }) => {
  const settings = useCustomerPanelSettings();

  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: BOX_GRAY,
        borderRadius: `${settings.border_radius}px` || "8px",
        padding: "10px",
        marginBottom: "5px",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
