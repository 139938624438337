import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerPanelSettings } from "../contexts/CustomerPanelSettingsContext";
import { getMoreQuestions } from "../helpers/primaryApiFunctions";
import { formatTimeAgo } from "../utils/formatTimeAgo";
import { Badge } from "./ui/Badge";
import { Card } from "./ui/Card";
import { Text } from "./ui/Text";

export const MoreQuestionsEmbedded = ({
  question,
  externalUserId,
  panelId,
  isMobile,
  handleNewActiveQuestion,
}) => {
  const settings = useCustomerPanelSettings();
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const pageSize = 3;

  const handleMoreQuestions = async (extendQuestions) => {
    setLoading(true);
    const items = await getMoreQuestions(
      panelId,
      question.id,
      externalUserId,
      page,
      pageSize
    );
    if (extendQuestions) {
      setQuestions([...questions, ...items]);
    } else {
      setQuestions(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panelId && question?.id && page !== 1) {
      handleMoreQuestions(true);
    }
  }, [page]);

  useEffect(() => {
    if (panelId && question?.id) {
      setPage(1);
      handleMoreQuestions(false);
    }
  }, [question, panelId]);

  return (
    <div className="mento-more-questions-wrapper" style={{ height: "100%" }}>
      <Text
        variant="subtitle"
        style={{ paddingTop: "12px", marginBottom: "4px" }}
      >
        Andra har även svarat på
      </Text>
      <Card style={{ height: "100%" }}>
        <div>
          {questions.length === 0 && !loading && (
            <Text color="gray">Vi hittar tyvärr inga fler frågor</Text>
          )}
          <div>
            {questions.map((q) => {
              const link = q.question_webpage_relationships?.find(
                (qwr) => qwr.webpages.type !== "RESULT_PAGE"
              )?.webpages?.link_url;
              return (
                <FadeIn key={q.id}>
                  <div style={{ marginBottom: "12px" }}>
                    <a href={link} target="_blank">
                      <Text
                        variant="subtitle"
                        style={{
                          color: settings.accent_color,
                          fontWeight: "bold",
                        }}
                      >
                        {q.title}
                      </Text>{" "}
                      <div style={{ display: "flex", gap: "4px" }}>
                        {q?.published_date && (
                          <Badge color="gray">
                            <Text variant="small">
                              {formatTimeAgo(q.published_date)}
                            </Text>
                          </Badge>
                        )}
                        <Badge color="gray">
                          <Text variant="small">
                            {q?.answers?.[0]?.count >=
                            settings.display_total_answers_after
                              ? q?.answers?.[0]?.count + " svar"
                              : "Under " +
                                settings.display_total_answers_after +
                                " svar"}
                          </Text>
                        </Badge>
                      </div>
                    </a>
                  </div>
                </FadeIn>
              );
            })}
          </div>

          {questions.length >= page * pageSize && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setPage(page + 1)}
            >
              <Text
                variant="subtitle"
                style={{
                  color: settings.accent_color,
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Se fler frågor
              </Text>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
