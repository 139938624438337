import { useEffect, useRef, useState } from "react";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import {
  fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion,
  fetchUserAnswer,
} from "../helpers/primaryApiFunctions";
import { calculatePercentage } from "../utils/formatAnswerOptions";
import { BOX_GRAY, TEXT_GRAY } from "../utils/theme";
import { AnswerQuestion } from "./AnswerQuestion";
import BlurredResults from "./BlurredResults";
import ResultItem from "./ui/ResultItem";

export const ProfileQuestionAnswerOptions = ({
  question,
  originalQuestionId,
  externalUserId,
  webpageId,
}) => {
  const [formattedAnswerOptions, setFormattedAnswerOptions] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [userAnswerOptionId, setUserAnswerOptionId] = useState(null);
  const [blurResult, setBlurResult] = useState(true);
  const theme = useCustomerTheme();
  const scrollRef = useRef(null);

  const barStyle = {
    backgroundColor: BOX_GRAY,
    borderRadius: `${theme.border_radius}px` || "8px",
    padding: "10px",
    marginBottom: "5px",
    fontSize: "medium",
  };

  useEffect(() => {
    // Todo: Check if the current user has answered. If yes, fetchAnswerOptionsStatistics and display result. If no, display answer question.
    const checkIfUserHasAnswered = async () => {
      const data = await fetchUserAnswer(question.id, externalUserId);
      setUserAnswerOptionId(data);
    };
    checkIfUserHasAnswered();
  }, [externalUserId, question.id]);

  useEffect(() => {
    const fetchAnswerOptionsStatistics = async () => {
      const data =
        await fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion(
          originalQuestionId,
          question.id,
          externalUserId
        );

      if (data) {
        const formattedData = data.reduce((acc, item) => {
          let existingOption = acc.find(
            (option) =>
              option.related_answer_option_id === item.related_answer_option_id
          );

          if (!existingOption) {
            existingOption = {
              related_answer_option_id: item.related_answer_option_id,
              related_answer_title: item.related_answer_title,
              items: [],
              totalAmount: 0,
            };
            acc.push(existingOption);
          }

          existingOption.totalAmount += item.amount;
          existingOption.items.push({
            id: item.answer_option_id,
            text: item.answer_title,
            amount: item.amount,
            selected: item.selected,
          });
          return acc;
        }, []);
        formattedData.forEach((option) => {
          option.items = option.items.map((item) => ({
            ...item,
            percentage: calculatePercentage(option.totalAmount, item.amount),
          }));
        });
        setFormattedAnswerOptions(
          formattedData.sort((a, b) => b.totalAmount - a.totalAmount)
        );
      }
    };

    if (userAnswerOptionId) {
      fetchAnswerOptionsStatistics();
    }
  }, [userAnswerOptionId, originalQuestionId]);

  const scrollContainer = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 200; // Adjust scroll amount as needed
      scrollRef.current.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return userAnswerOptionId ? (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          style={{ marginRight: "6px", color: theme.accent_color }}
          onClick={() => scrollContainer(-1)}
        >
          <TfiAngleLeft />
        </button>
        <div
          className="mento-scroll-container"
          style={{
            display: "flex",
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            width: "100%",
          }}
          ref={scrollRef}
        >
          {formattedAnswerOptions.map((fao) => (
            <div
              key={fao.related_answer_option_id}
              style={{
                flex: "0 0 auto",
                scrollSnapAlign: "start",
                width: "100%", // Adjust width as needed
                marginRight: "10px",
              }}
            >
              <span style={{ fontWeight: "bold", color: TEXT_GRAY }}>
                {fao.related_answer_title}{" "}
                {fao.related_answer_option_id === userAnswerOptionId &&
                  "(Ditt svar)"}
              </span>
              <div style={barStyle}>
                {fao.items.map((item, index) => (
                  <div key={index}>
                    <ResultItem item={item} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <button
          style={{ marginLeft: "6px", color: theme.accent_color }}
          onClick={() => scrollContainer(1)}
        >
          <TfiAngleRight />
        </button>
      </div>
    </div>
  ) : blurResult ? (
    <BlurredResults onViewResultClick={() => setBlurResult(false)} />
  ) : (
    <div>
      <span style={{ fontWeight: "bold", color: TEXT_GRAY }}>
        {question.title}
      </span>
      <AnswerQuestion
        webpageId={webpageId}
        question={question}
        externalUserId={externalUserId}
        onClick={(answerOptionId) => setUserAnswerOptionId(answerOptionId)}
        skipFeeling
      />
    </div>
  );
};
