import React from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { CustomCTA } from "./CustomCTA";
import { Footer } from "./Footer";
import { MainContentBox } from "./MainContentBox";
import { MainContentBoxExpanded } from "./MainContentBoxExpanded";
import { iconSize } from "./Start";
import { PluginButton } from "./ui/PluginButton";

function PluginStart({
  activeQuestion,
  questionLoading,
  displayBox,
  setDisplayBox,
  user,
  allowAnonymous,
  userAnswerOptionId,
  handleNewActiveQuestion,
  panelId,
  fetchQuestionFromCurrentActiveQuestion,
  toggleWindowSize,
  activeWebpageId,
  windowWidth,
  settings,
  isManuallyHidden,
  setIsManuallyHidden,
  contentRef,
  isMobile,
}) {
  const position = settings?.position || "RIGHT";
  const isExpanded = windowWidth > 250;

  let pluginWrapperStyle = {
    position: "fixed",
    bottom: `${settings.position_padding_bottom}px`,
    right: `${settings.position_padding_horizontal}px`,
    zIndex: 2000,
    fontFamily:
      "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontSize: "small",
  };

  let pluginContentStyle = {
    position: "absolute",
    height: `auto`,
    width: `${windowWidth}px`,
    bottom: `${iconSize + 10}px`,
    left: `-${windowWidth - (iconSize + 10)}px`,
    boxSizing: "border-box",
    borderRadius: `${settings.border_radius}px` || "8px",
    border: `1px solid rgb(98, 98, 98, 0.5)`,
    maxHeight: isMobile ? "70vh" : "500px",
    overflowY: "scroll",
    padding: "12px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 0px 30px 10px rgba(0, 0, 0, 0.2)",
    lineHeight: "1.4",
  };

  if (position === "LEFT") {
    pluginWrapperStyle.left = `${settings.position_padding_horizontal}px`;
    pluginWrapperStyle.right = "auto";
    pluginContentStyle.left = "auto";
    pluginContentStyle.right = `-${windowWidth - (iconSize + 10)}px`;
  }

  if (
    displayBox &&
    isExpanded &&
    isMobile &&
    activeQuestion &&
    (!!user || allowAnonymous)
  ) {
    return (
      <FadeIn transitionDuration={100} visible={displayBox && !questionLoading}>
        <div
          style={{ ...pluginWrapperStyle, bottom: 0, right: 0, left: 0 }}
          id="mento-plugin-wrapper-fullscreen"
        >
          <div
            ref={contentRef}
            style={{
              ...pluginContentStyle,
              width: "100%",
              bottom: 0,
              left: 0,
              right: 0,
            }}
            id="mento-plugin-content-fullscreen"
          >
            <MainContentBoxExpanded
              externalUserId={user?.id}
              userAnswerOptionId={userAnswerOptionId}
              handleCloseClick={() => setDisplayBox(false)}
              handleNewActiveQuestion={handleNewActiveQuestion}
              panelId={panelId}
              question={activeQuestion}
              refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
              toggleWindowSize={toggleWindowSize}
              webpageId={activeWebpageId}
            />
            <Footer />
          </div>
        </div>
      </FadeIn>
    );
  }

  return (
    <FadeIn delay={200} visible={activeQuestion}>
      {activeQuestion && (
        <div style={pluginWrapperStyle} id="mento-plugin-wrapper">
          <PluginButton
            hideClick={() => setIsManuallyHidden(true)}
            click={() => setDisplayBox(!displayBox)}
            active={displayBox}
          />
          {displayBox && !questionLoading && (
            <FadeIn>
              <div
                ref={contentRef}
                style={pluginContentStyle}
                id="mento-plugin-content"
              >
                {!user && !allowAnonymous ? (
                  <CustomCTA question={activeQuestion} />
                ) : isExpanded ? (
                  <MainContentBoxExpanded
                    externalUserId={user?.id}
                    userAnswerOptionId={userAnswerOptionId}
                    handleCloseClick={() => setDisplayBox(false)}
                    handleNewActiveQuestion={handleNewActiveQuestion}
                    panelId={panelId}
                    question={activeQuestion}
                    refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
                    toggleWindowSize={toggleWindowSize}
                    webpageId={activeWebpageId}
                  />
                ) : (
                  <MainContentBox
                    externalUserId={user?.id}
                    userAnswerOptionId={userAnswerOptionId}
                    question={activeQuestion}
                    panelId={panelId}
                    refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
                    toggleWindowSize={toggleWindowSize}
                    webpageId={activeWebpageId}
                  />
                )}
                <Footer />
              </div>
            </FadeIn>
          )}
        </div>
      )}
    </FadeIn>
  );
}

export default PluginStart;
